
import { SettingsModel } from '@/modules/settings/settings-model';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import SettingsToolbar from '@/modules/settings/components/settings-toolbar.vue';
import SettingsTablePage from '@/modules/settings/components/settings-list-table.vue';
import { routerAsync } from '@/app-module';
import { i18n } from '@/i18n';

const { fields } = SettingsModel;
const formSchema = new FormSchema([
  fields.theme,
  fields.barcodeTitle,
  fields.title,
  fields.barcodeName,
  fields.signature,
]);

export default {
  name: 'app-settings-page',

  components: {
    [SettingsToolbar.name]: SettingsToolbar,
    [SettingsTablePage.name]:SettingsTablePage,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      barcodeTitle: undefined,
      partOne:null,
      partTwo:null,
      confirm:false,
      
    };
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      settings: 'settings/settings',
      findLoading: 'settings/findLoading',
      saveLoading: 'settings/saveLoading',
      oldSettings:'settings/oldSettings'
    }),

  securedDelete(){
    return !!this.settings.pinCode
  },
  signatureWasFound(){
    return !!this.model.signature
  },
    fields() {
      return fields;
    },
  },

  async created() {
    this.doFetchOldDate()
    await this.doFind();
    this.model = formSchema.initialValues(this.settings);
    if (!this.model.barcodeTitle) this.model.barcodeTitle = 'Puricom Egypt' 
  },

  methods: {
    ...mapActions({
      doFind: 'settings/doFind',
      doSave: 'settings/doSave',
      doFetchOldDate:'settings/doFetchOldDate'
    }),
    addSignature(data){
      this.model.signature = data
      this.confirm = false
    },
    i18n(code, args) {
      return i18n(code, args)
    },
    doReset() {
      this.model = formSchema.initialValues(this.settings);
    },
    doCancel() {
      routerAsync().push('/');
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.model.barcodeTitle = this.showTitle?`${this.model.title} ${this.model.barcodeName}`:this.model.barcodeName
      const values = formSchema.cast(this.model);
      return this.doSave(values);
    },
  },
};
